import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import globalComponents from '@/components';
// 国家化电话插件
import 'maz-ui/css/main.css';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import vue3SeamlessScroll from 'vue3-seamless-scroll';
import VueCookies from 'vue3-cookies';
import router from './router';
import store from './store';
import i18n from './locale';
import directive from './directive';
import App from './App.vue';
import '@arco-design/web-vue/dist/arco.css';
import '@arco-themes/vue-theme-line/index.less';
import '@/assets/style/global.less';
import '@/api/interceptor';
import './assets/font/iconfont.css';

import './assets/iconfont/index.css';

// eslint-disable-next-line import/no-unresolved
// import 'virtual:windi.css'

const app = createApp(App);
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://95f145d43b694aa78e94ac424d99349d@9uv8e4greport.basicex.com/18',
    integrations: [
      // @ts-ignore
      new BrowserTracing({
        // @ts-ignore
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

app.component('MazPhoneNumberInput', MazPhoneNumberInput);
app.use(vue3SeamlessScroll);
app.use(ArcoVue, {});
app.use(ArcoVueIcon);
app.use(router);
app.use(store);
app.use(i18n);
app.use(globalComponents);
app.use(directive);
app.use(VueCookies);

app.mount('#app');
